/**
* @copyright 2019 @ DigiNet
* @author TRIHAO
* @create 11/8/2019
* @Example
*/

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 8px !important;
}

.MuiFormControl-root {
  &.Mui-error {
    color: #f44336;
    .MuiCheckbox-root:not(.Mui-disabled) {
      color: #f44336;
    }
  }
}

